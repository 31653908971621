import http from '@/config/axios.config'

// 获取wfs设置
export function getWFSSetting() {
    return http({
        url: `/rest/services/wfs/settings`,
        method: "get",
    })
}

export function getWFSSettingByWorkspaceName(name) {
    return http({
        url: `/rest/services/wfs/workspaces/`+name+`/settings`,
        method: "get",
    })
}

// 更新wfs设置
export function updateWFSSetting(data) {
    return http({
        url: `/rest/services/wfs/settings`,
        method: "put",
        data: data
    })
}

export function updateWFSSettingByWorkspaceName(name, data) {
    return http({
        url: `/rest/services/wfs/workspaces/`+name+`/settings`,
        method: "put",
        data: data
    })
}