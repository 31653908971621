<template>
  <div class="bg">
    <a-form :form="form">
      <h1>Web矢量服务(Web Feature Service)设置</h1>
      <!-- <a-form-item label="工作空间">
        <a-select
          v-model="selectedWorkspaceName"
          @change="refreshData"
          style="
            width: 166px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
          "
        >
          <a-select-option
            v-for="ws in workspaces"
            :key="ws.name"
            :value="ws.name"
            >{{ ws.name }}</a-select-option
          >
        </a-select>
      </a-form-item> -->
      <h2>要素</h2>
      <a-form-item label="最大要素数量" style="display: flex">
        <a-input-number
          v-model="maximum_number_of_features"
          :min="1"
          :default-value="18"
        />
      </a-form-item>
      <a-form-item
        label="最大预览要素数量(0则代表最大要素数量)"
        style="display: flex"
      >
        <a-input-number
          v-model="maximum_number_of_features_for_preview"
          :min="0"
          :default-value="18"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="saveProp">保存属性</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
// import { getWorkspaces } from "@/api/data/workspace.js";
import {
  getWFSSetting,
  getWFSSettingByWorkspaceName,
  updateWFSSetting,
  updateWFSSettingByWorkspaceName,
} from "@/api/service/service.js";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      workspaces: [],
      selectedWorkspaceName: "全部",
      data: null,
      maximum_number_of_features: 0,
      maximum_number_of_features_for_preview: 0,
    };
  },
  methods: {
    refreshData(value) {
      if (value == "全部") {
        getWFSSetting().then((res) => {
          if (res.status == 200) {
            this.setData(res);
          }
        });
      } else {
        getWFSSettingByWorkspaceName(value).then((res) => {
          if (res.status == 200) {
            this.setData(res);
          } else {
            this.selectedWorkspaceName = "全部";
          }
        });
      }
    },
    setData(res) {
      this.data = res.data;
      this.maximum_number_of_features = res.data.wfs.maxFeatures;
      if (res.data.wfs.metadata) {
        res.data.wfs.metadata.entry.forEach((item) => {
          if (item["@key"] == "maxNumberOfFeaturesForPreview") {
            this.maximum_number_of_features_for_preview = item["$"];
          }
        });
      }
    },
    saveProp() {
      if (this.data != null) {
        this.data.wfs.maxFeatures = this.maximum_number_of_features;
        this.data.wfs.metadata.entry = [
          { "@key": "SHAPE-ZIP_DEFAULT_PRJ_IS_ESRI", $: "false" },
          {
            "@key": "maxNumberOfFeaturesForPreview",
            $: this.maximum_number_of_features_for_preview,
          },
        ];
        if (this.selectedWorkspaceName == "全部") {
          updateWFSSetting(this.data).then((res) => {
            if (res.status == 200) {
              this.$message.success("更新wfs设置成功");
            } else {
              this.$message.error("更新wfs设置失败");
            }
          });
        } else {
          updateWFSSettingByWorkspaceName(
            this.selectedWorkspaceName,
            this.data
          ).then((res) => {
            if (res.status == 200) {
              this.$message.success(
                "更新工作区" + this.selectedWorkspaceName + "的wfs设置成功"
              );
            }
          });
        }
      }
    },
  },
  mounted() {
    // getWorkspaces().then((res) => {
    //   this.workspaces = [{ name: "全部" }];
    //   if (res.status == 200) {
    //     this.workspaces = this.workspaces.concat(res.data);
    //   }
    // });
    this.refreshData("全部");
    // console.log(window.sessionStorage.getItem("token"));
  },
};
</script>
<style scoped>
.bg {
  position: relative;
  padding: 20px 0px 20px 20px;
  background: #fff;
}
.save {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>